import { useEffect, useState } from "react";
import { PHONE_CONTACT_NUMBER_DEFAULT, PHONE_CONTACT_NUMBER_NEW_CARS, PHONE_CONTACT_NUMBER_USED_CARS, WHATSAPP_CONTACT_NUMBER } from "@globalConfig";
import { usePostDetails } from "@/common/Contexts/PostDetailsContext";
var normalizeNumberForDisplay = function normalizeNumberForDisplay(num) {
  return num === null || num === void 0 ? void 0 : num.replace("+966", "");
};
function useContactNumbers() {
  var _useState = useState(WHATSAPP_CONTACT_NUMBER.substring(1)),
    whatsappCallNumber = _useState[0],
    setWhatsappCallNumber = _useState[1];
  var _useState2 = useState(normalizeNumberForDisplay(WHATSAPP_CONTACT_NUMBER)),
    whatsappDisplayNumber = _useState2[0],
    setWhatsappDisplayNumber = _useState2[1];

  // Call center numbers
  var _useState3 = useState(PHONE_CONTACT_NUMBER_DEFAULT),
    callCenterCallNumber = _useState3[0],
    setCallCenterCallNumber = _useState3[1];
  var _useState4 = useState(normalizeNumberForDisplay(PHONE_CONTACT_NUMBER_DEFAULT)),
    callCenterDisplayNumber = _useState4[0],
    setCallCenterDisplayNumber = _useState4[1];
  var _usePostDetails = usePostDetails(),
    carCondition = _usePostDetails.carCondition;
  useEffect(function () {
    if (carCondition === "new") {
      setCallCenterCallNumber(PHONE_CONTACT_NUMBER_NEW_CARS);
      setCallCenterDisplayNumber(normalizeNumberForDisplay(PHONE_CONTACT_NUMBER_NEW_CARS));
    } else if (carCondition === "used") {
      setCallCenterCallNumber(PHONE_CONTACT_NUMBER_USED_CARS);
      setCallCenterDisplayNumber(normalizeNumberForDisplay(PHONE_CONTACT_NUMBER_USED_CARS));
    } else {
      setCallCenterCallNumber(PHONE_CONTACT_NUMBER_DEFAULT);
      setCallCenterDisplayNumber(normalizeNumberForDisplay(PHONE_CONTACT_NUMBER_DEFAULT));
    }
  }, [carCondition]);
  return {
    whatsappCallNumber: whatsappCallNumber,
    whatsappDisplayNumber: whatsappDisplayNumber,
    callCenterCallNumber: callCenterCallNumber,
    callCenterDisplayNumber: callCenterDisplayNumber
  };
}
export default useContactNumbers;